import _container from "./container";
import _parser from "./parser";
import _input from "./input";
var exports = {};
let Container = _container;
let Parser = _parser;
let Input = _input;
function parse(css, opts) {
  let input = new Input(css, opts);
  let parser = new Parser(input);
  try {
    parser.parse();
  } catch (e) {
    throw e;
  }
  return parser.root;
}
exports = parse;
parse.default = parse;
Container.registerParse(parse);
export default exports;